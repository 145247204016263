import siteDetails from '../../Utils/site'
import tapImage from '../../assets/Images/slider-bg-image.png'
import { NavLink } from 'react-router-dom'

const SliderComponent = () => {
  return (
    <div className="relative isolate overflow-hidden py-24 sm:py-32">
      <img
        src={tapImage}
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
      />
      <div className="mx-auto max-w-2xl py-20">
        <div className="text-center p-4">
          <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
            {siteDetails.name}
          </h1>
          <p className="mt-6 text-lg leading-8 text-white">
            {siteDetails.description}
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <NavLink
              to="/our-products"
              className="rounded-md site-primary-background px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              target="_blank"
            >
              Our Products
            </NavLink>
            <NavLink
              to="/about-us"
              className="text-sm font-semibold leading-6 text-white"
              target="_blank"
            >
              About us <span aria-hidden="true">→</span>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SliderComponent
