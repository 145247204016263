import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import siteDetails from '../../Utils/site'

const SocialMediaStickyComponent = () => {
  return (
    <div className="btn btn-primary social-media-sticky">
      <a
        href={`https://wa.me/${siteDetails.founder.phone}`}
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>
          <FontAwesomeIcon
            icon={faWhatsapp}
            className="text-primary address-icon"
          />
        </span>
        <i className="fa fa-whatsapp whatsapp-icon"></i>
      </a>
    </div>
  )
}

export default SocialMediaStickyComponent
