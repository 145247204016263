import siteDetails from '../../Utils/site'
import founderImage from '../../assets/Images/Ajanta Bhattarai -  Everest Bath Fitting.png'

const AboutFounderComponent = () => {
  return (
    <div className="relative isolate overflow-hidden py-24 sm:py-20 light-background">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <div className="grid grid-cols-1 gap-8 sm:mt-10 lg:mt-10 lg:grid-cols-4">
            <div className="lg:col-span-3">
              <h2 className="text-4xl font-bold tracking-tight text-black sm:text-6xl">
                About Founder
              </h2>
              <p className="mt-4 text-lg leading-8 text-black">
                {siteDetails.founder.bio}
              </p>
            </div>

            <div>
              <img src={founderImage} />
              <ul className="mt-4">
                <li> {siteDetails.founder.name}</li>
                <li> {siteDetails.founder.title}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutFounderComponent
