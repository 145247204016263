import React from 'react'
import SliderComponent from '../../Component/Slider'
import MessageComponent from '../../Component/Message'
import OurCollectionComponent from '../../Component/OurCollection'
import OurProductComponent from '../../Component/OurProduct'
import aboutUsImage from '../../assets/Images/our-tap-design.png'

const HomePage = () => {
  const aboutSectionContent = () => {
    return (
      <div className="relative isolate overflow-hidden py-24 sm:py-20 light-background">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <h2 className="text-4xl font-bold tracking-tight text-black sm:text-6xl">
              About Us
            </h2>
            <div className="grid grid-cols-1 gap-8 sm:mt-10 lg:mt-10 lg:grid-cols-2">
              <div>
                <p className="text-lg leading-8 text-black">
                  With nearly 10 years’ experience, we know how important
                  bathrooms are to comfort and well-being in your home. That’s
                  why, as the Nepal's foremost bathroom specialists, our highest
                  priority is helping you to create the bathroom of your dreams.
                  At Everest Bath Fitting it’s all about the personal touch.
                  From the showroom to your doorstep, our design experts are
                  ready to help you every step of the way and you can trust us
                  to deliver quality products, with outstanding customer
                  service, for the best possible price.
                  <ul className="mt-4">
                    <li>→ An ISO 2015 Certified Company</li>
                    <li>→ Nepal's No: 1 Product</li>
                    <li>→ Customer Satisfaction</li>
                  </ul>
                </p>
              </div>

              <div>
                <img src={aboutUsImage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <SliderComponent />
      {aboutSectionContent()}
      <MessageComponent
        message=" We vision to become a leader in complete bathing solutions and to
              ensure total customer delight through products high on quality and
              style."
        bgColorClass="site-secondary-background"
      />
      <OurCollectionComponent />
      <MessageComponent
        message="We believe to make our customers always happy with our Service!"
        submessage="Lots of happy clients are connected with us.."
        bgColorClass="site-primary-background"
      />
      <OurProductComponent />
    </div>
  )
}

export default HomePage
