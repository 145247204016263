import React from 'react'
import MessageComponent from '../../Component/Message'
import aboutUsImage from '../../assets/Images/our-tap-design.png'
import AboutFounderComponent from '../../Component/AboutFounder'

const AboutUsPage = () => {
  const ourVisionContent = () => {
    return (
      <div className="relative isolate overflow-hidden py-24 sm:py-20 light-background">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-4xl font-bold tracking-tight text-black sm:text-6xl">
              Our Vision
            </h2>
            <p className="text-lg mt-4 leading-8 text-black text-center">
              Every product we design and offer is centered around our four core
              values.
            </p>
          </div>
          <div className="grid grid-cols-1 gap-8 sm:mt-10 lg:mt-10 lg:grid-cols-2 text-center">
            <div className="lg:col-span-1 vision-card-item">
              <p>
                <b>Reliable</b>
              </p>
              <p>
                We take pride in what we do and ensure excellence through a
                quality assurance process that allows us to offer a 10-year
                warranty.
              </p>
            </div>

            <div className="lg:col-span-1 vision-card-item">
              <p>
                <b>Leadership</b>
              </p>
              <p>
                We manage and build courage to navigate towards the desired
                future.
              </p>
            </div>

            <div className="lg:col-span-1 vision-card-item">
              <p>
                <b>Accessible</b>
              </p>
              <p>
                Our teams are easily approachable and dedicated to ensuring high
                customer satisfaction.
              </p>
            </div>

            <div className="lg:col-span-1 vision-card-item">
              <p>
                <b>Affordable</b>
              </p>
              <p>
                We maintain good quality that is sincerely made and honestly
                priced.
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const ourMissionContent = () => {
    return (
      <>
        <h3 className="text-2xl font-bold tracking-tight text-black sm:text-4xl">
          Mission
        </h3>
        <ul className="mt-4">
          <li className="mt-2">
            → <b>Commitment Towards Consumers:</b> We are dedicated to our
            consumers through continuous efforts to produce high-quality,
            reliable products that are sincerely made and priced honestly.
          </li>
          <li className="mt-2">
            → <b>Environment:</b> We strive for the most efficient and
            water-saving designs to contribute to environmental sustainability.
          </li>
          <li className="mt-2">
            → <b>Employees:</b> We are committed to our employees through good
            leadership, providing a congenial working environment, and fostering
            motivation in our organizational culture.
          </li>
        </ul>
      </>
    )
  }

  const aboutUsSectionContent = () => {
    return (
      <div className="relative isolate overflow-hidden py-24 sm:py-20 light-background">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-4xl font-bold tracking-tight text-black sm:text-6xl">
              About Us
            </h2>
            <p className="text-lg mt-4 leading-8 text-black text-center">
              Crafting Quality Products with Passion.
            </p>
          </div>

          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <div className="grid grid-cols-1 gap-8 sm:mt-10 lg:mt-10 lg:grid-cols-3">
              <div className="lg:col-span-2">
                <p className="text-lg leading-8 text-black">
                  With nearly 10 years of experience, we know how important
                  bathrooms are to comfort and well-being in your home. That’s
                  why, as Nepal’s foremost bathroom specialists, our highest
                  priority is helping you to create the bathroom of your dreams.
                </p>
                <br />
                <p className="text-lg leading-8 text-black">
                  At Everest Bath Fitting, it’s all about the personal touch.
                  From the showroom to your doorstep, our design experts are
                  ready to help you every step of the way and you can trust us
                  to deliver quality products, with outstanding customer
                  service, for the best possible price.
                </p>
                <div className="mt-4">{ourMissionContent()}</div>
              </div>

              <div className="lg:col-span-1">
                <img src={aboutUsImage} alt="Everestbath Fitting About Us" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {aboutUsSectionContent()}
      {ourVisionContent()}
      <MessageComponent
        message="We vision to become a leader in complete bathing solutions and to
              ensure total customer delight through products high on quality and
              style."
        bgColorClass="site-secondary-background"
      />
      <AboutFounderComponent />
    </div>
  )
}

export default AboutUsPage
