import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { contactInformation, socialMediaList } from '../../Utils'
import navigationMenuList from '../../Utils/menulist'
import siteDetails from '../../Utils/site'
import SocialMediaStickyComponent from '../WhatsappIcon'
import { NavLink } from 'react-router-dom'

let currentDate = new Date()
let currentYear = currentDate.getFullYear()

const FooterComponent = () => {
  return (
    <div className="relative isolate overflow-hidden pt-24 sm:pt-20 bg-gray-900">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:grid-cols-3">
          <div>
            <p className="mx-auto flex max-w-xs flex-col gap-y-4 text-white text-2xl pb-4">
              Contact Information
            </p>
            {contactInformation.map((item: any) => {
              return (
                <div
                  className="mx-auto flex max-w-xs flex-col gap-y-4 text-white"
                  key={item.id}
                >
                  <p className="text-white"> {item.value}</p>
                </div>
              )
            })}
          </div>
          <div>
            <p className="mx-auto flex max-w-xs flex-col gap-y-4 text-white text-2xl pb-4">
              Quick Links
            </p>
            {navigationMenuList.map((item: any) => {
              return (
                <div
                  className="mx-auto flex max-w-xs flex-col gap-y-4 text-white"
                  key={item.id}
                >
                  <NavLink
                    className="text-white"
                    to={item.path}
                    target="_blank"
                  >
                    → {item.name}
                  </NavLink>
                </div>
              )
            })}
          </div>

          <div>
            <p className="mx-auto flex max-w-xs flex-col gap-y-4 text-white text-2xl pb-4">
              Follow us on
            </p>
            <div className="ml-6">
              {socialMediaList.map((item: any) => {
                return (
                  <span
                    className="mx-auto max-w-xs flex-col gap-y-4 text-white social-media-info"
                    key={item.id}
                  >
                    <a className="text-white" href={item.url} target="_blank">
                      <FontAwesomeIcon
                        icon={item.icon}
                        className="text-white"
                      />
                    </a>
                  </span>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <hr className="mt-10 mb-4" />
      <div className="text-white text-center pb-4 sm:pb-4">
        <NavLink to="/" className="footer-site-name">{siteDetails.name} Pvt Ltd.</NavLink> All rights
        reserved, {currentYear}.
      </div>
      <SocialMediaStickyComponent />
    </div>
  )
}

export default FooterComponent
