import productImage1 from '../../assets/Images/products/1.png'
import productImage2 from '../../assets/Images/products/2.png'
import productImage3 from '../../assets/Images/products/3.png'
import productImage4 from '../../assets/Images/products/4.png'

const productList = [
  { id: 1, image: productImage1 },
  { id: 2, image: productImage2 },
  { id: 3, image: productImage3 },
  { id: 4, image: productImage4 },
]

const OurProductComponent = () => {
  return (
    <div className="relative isolate overflow-hidden py-24 sm:py-20 light-background">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <div className="text-center">
            <h2 className="text-4xl font-bold tracking-tight text-black sm:text-6xl">
              Our Product
            </h2>
            <p className="text-lg mt-4 leading-8 text-black text-center">
              We have wide variety of products.
            </p>
          </div>
          <div className="py-24 sm:py-10">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="grid grid-cols-1 gap-x-8 gap-y-16 text-center sm:grid-cols-2 lg:grid-cols-4">
                {productList.map((item: any) => {
                  return (
                    <div
                      className="mx-auto flex max-w-xs flex-col gap-y-4"
                      key={item.id}
                    >
                      <img src={item.image} />
                      {/* <p> {item.name}</p> */}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurProductComponent
