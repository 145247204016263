import ClassicCollectionImage from '../../assets/Images/collection/classic-collection.jpeg'
import CorsaCollectionImage from '../../assets/Images/collection/corsa-collection.jpeg'
import OliveCollectionImage from '../../assets/Images/collection/olive-collection.jpeg'
import SilkCollectionImage from '../../assets/Images/collection/silk-collection.jpeg'
import StickCollectionImage from '../../assets/Images/collection/stick-collection.jpeg'

const collectionList = [
  { id: 1, name: 'Classic Collection', image: ClassicCollectionImage },
  { id: 2, name: 'Corsa Collection', image: CorsaCollectionImage },
  { id: 3, name: 'Olive Collection', image: OliveCollectionImage },
  { id: 4, name: 'Silk Collection', image: SilkCollectionImage },
  { id: 5, name: 'Stick Collection', image: StickCollectionImage },
]

const OurCollectionComponent = () => {
  return (
    <div className="relative isolate overflow-hidden py-24 sm:py-20 light-background">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <div className="text-center">
            <h2 className="text-4xl font-bold tracking-tight text-black sm:text-6xl">
              Our Collection
            </h2>
            <p className="text-lg mt-4 leading-8 text-black text-center">
              We have wide variety of product collection.
            </p>
          </div>
          <div className="py-24 sm:py-10">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="grid grid-cols-1 gap-x-8 gap-y-16 text-center sm:grid-cols-2 lg:grid-cols-3">
                {collectionList.map((item: any) => {
                  return (
                    <div
                      className="mx-auto flex max-w-xs flex-col gap-y-4"
                      key={item.id}
                    >
                      <img src={item.image} />
                      <p> {item.name}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurCollectionComponent
