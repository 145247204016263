import React from 'react'
import MessageComponent from '../../Component/Message'
import OurCollectionComponent from '../../Component/OurCollection'
import OurProductComponent from '../../Component/OurProduct'

const OurProductsPage = () => {
  return (
    <div>
      <OurProductComponent />
      <MessageComponent
        message="We believe to make our customers always happy with our Service!"
        submessage="Lots of happy clients are connected with us.."
        bgColorClass="site-primary-background"
      />
      <OurCollectionComponent />
    </div>
  )
}

export default OurProductsPage
