import React from 'react'
import { NavLink } from 'react-router-dom'

interface IProps {
  message?: string
  submessage?: string
  bgColorClass: string
}

const MessageComponent = (props: IProps) => {
  const { message, submessage, bgColorClass } = props

  return (
    <div className={`${bgColorClass} py-24 sm:py-32`}>
      <div className="mx-auto max-w-7xl px-6">
        <div className="gap-x-8 gap-y-16 text-left">
          <div className="mx-auto flex flex-col gap-y-4">
            <p className="text-3xl font-semibold tracking-tight text-white text-center sm:text-4xl">
              {message}
            </p>
            <p className="font-semibold tracking-tight text-white text-center">
              {submessage}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <NavLink
          to="/our-products"
          className="rounded-md bg-white text-black px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          target='_blank'
        >
          Our Products →
        </NavLink>
      </div>
    </div>
  )
}

export default MessageComponent
