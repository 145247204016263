const siteDetails = {
  name: 'Everest Bath Fittings',
  tagline: 'Nepal’s Foremost Bathroom Specialists.',
  description:
    "Elevate your home's comfort with Everest Bath Fitting – Nepal's top bathroom specialists, delivering dream bathrooms with personalized service and quality products for nearly a decade.",
  vision:
    'To become leader in complete bathing solutions and to ensure total customer delight through products high on quality and style.',
  mission: {
    data: [
      {
        title: 'Commitment Towards Consumers',
        description:
          'Through a continuous efforts to produce high quality, reliable products that is sincerely made and honestly priced.',
      },
      {
        title: 'Environment',
        description: 'Through most efficient and water saving design.',
      },
      {
        title: 'Employees',
        description:
          'Through good leadership, providing congenial working environment and developing motivation at organization culture.',
      },
    ],
  },
  values: {
    description:
      'Every product we design and offer produced around our four core values.',
    data: [
      {
        title: 'Reliable',
        description:
          'What we do. we do well. through quality assurance process that allow is to assure 10 years of warranty.',
      },
      {
        title: 'Leadership',
        description:
          'To manage and to build courage to head towards desired future.',
      },
      {
        title: 'Assessible',
        description:
          'Easily approachable and available as our teams are dedicated to ensure high customer satisfaction.',
      },
      {
        title: 'Affordable',
        description:
          'Maintaining a good quality that would be sincerely made and honestly priced.',
      },
    ],
  },
  speciality: [
    {
      title: 'Design',
      description:
        'Our products range from traditional mixers, showers valves and accessories to contemporary design including waterfall style spout, handle option in different styles and product that use the latest technology. Our global processes ensure we name at the cutting edge of international design trends.',
    },
    {
      title: 'Service',
      description:
        'We have a team of people dedicated to developing product range that innovate in their from and function. We design products that not only look great but are easy to install, maintain and minimize environment impact.',
    },
    {
      title: 'Quality',
      description:
        'All of our products are passed through straight quality assurance processes to ensure that they reach the exacting standard that allow us.',
    },
    {
      title: 'Innovation​',
      description:
        'We pride ourselves on delivering outstanding new products keeping in pace with new technology and design.',
    },
  ],
  founder: {
    name: 'Ajanta Bhattarai',
    title: 'Founder, Everest Bath Fitting Industries Pvt. Ltd.',
    bio: 'Ajanta Bhattarai is a young entrepreneur and passionate about elegant, beautiful, and quality bathroom and home products. He started the Everest Bath Fitting journey nearly 10 years ago and since then he believe in giving a personal touch to every product.',
    phone: '+9779857034038',
  },
}

export default siteDetails
