import React from 'react'
import NavBarComponent from '../Component/NavBar'
import FooterComponent from '../Component/Footer'
import AppRouter from '../Router'

const App = () => {
  return (
    <div className="App">
      <div>
        <NavBarComponent />
        <hr />
        <AppRouter />
        <FooterComponent />
      </div>
    </div>
  )
}

export default App
