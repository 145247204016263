import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import siteDetails from './site'

export const contactInformation = [
  {
    id: 1,
    label: 'address',
    value: 'Tilottama-6, Gorkatta, Butwal, Nepal',
    icon: faLocationDot,
  },
  {
    id: 2,
    label: 'email',
    value: 'everestbath@gmail.com',
    icon: faEnvelope,
  },
]

export const socialMediaList = [
  {
    label: 'Facebook',
    name: 'Facebook',
    icon: faFacebook,
    color: '#3b5998',
    url: 'https://www.facebook.com/profile.php?id=100063749053120',
  },
  {
    label: 'WhatsApp',
    name: 'WhatsApp',
    icon: faWhatsapp,
    color: '#25d366',
    url: `https://wa.me/${siteDetails.founder.phone}`,
  },
]
