import React from 'react'
import contactUsImage from '../../assets/Images/everestbath-fitting-google-map-location.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { contactInformation, socialMediaList } from '../../Utils'

const ContactUsPage = () => {
  const addressContent = () => {
    return (
      <div className="grid grid-cols-1 gap-8 lg:grid-cols-1 text-center">
        {contactInformation.map((item: any) => {
          return (
            <div className="lg:col-span-1 address-card-item">
              <p>
                <FontAwesomeIcon
                  icon={item.icon}
                  className="text-primary address-icon"
                />
              </p>
              <p>{item.value}</p>
            </div>
          )
        })}
        <div className="ml-3">
          {socialMediaList.map((item: any) => {
            return (
              <span
                className="mx-auto max-w-xs flex-col gap-y-4 social-media-info"
                key={item.id}
              >
                <a className="" href={item.url} target="_blank">
                  <FontAwesomeIcon icon={item.icon} className="" />
                </a>
              </span>
            )
          })}
        </div>
      </div>
    )
  }

  const contactUsSectionContent = () => {
    return (
      <div className="relative isolate overflow-hidden py-24 sm:py-20 light-background">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-4xl font-bold tracking-tight text-black sm:text-6xl">
              Contact Us
            </h2>
            <p className="text-lg mt-4 leading-8 text-black text-center">
              Reach Out to Us Anytime, Anywhere.
            </p>
          </div>

          <div className="mx-auto max-w-2xl lg:max-w-none">
            <div className="grid grid-cols-1 gap-8 sm:mt-10 lg:mt-10 lg:grid-cols-3">
              <div className="lg:col-span-2">
                <img
                  src={contactUsImage}
                  alt="Everestbath Fitting Contact Us"
                />
              </div>
              <div className="lg:col-span-1">{addressContent()}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return <div>{contactUsSectionContent()}</div>
}

export default ContactUsPage
