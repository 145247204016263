import React from 'react'
import { Route, Routes } from 'react-router-dom'
import HomePage from '../Pages/Home'
import AboutUsPage from '../Pages/AboutUs'
import ContactUsPage from '../Pages/ContactUs'
import OurProductsPage from '../Pages/OurProducts'

const AppRouter: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about-us" element={<AboutUsPage />} />
      <Route path="/contact-us" element={<ContactUsPage />} />
      <Route path="/our-products" element={<OurProductsPage />} />
    </Routes>
  )
}

export default AppRouter
